import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { _user } from 'std';

import { deviceHelper, formatAsCurrency } from 'utils/misc';

import { Icon, IconButton, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';

import { mdiCodeJson, mdiLockReset, mdiLogout, mdiAccountOff, mdiAccountCheck, mdiAccountKey } from '@mdi/js';
import MDIcon from '@mdi/react';

import UserForm from './Forms/UserForm';
import SingleRatingWidget from 'components/OperatorRatingWidgets/SingleRatingWidget';
import CRUDTable from './CRUDTable';
import PasswordResetDialog from './Dialogs/PasswordResetDialog';
import DisableAccountDialog from './Dialogs/DisableAccountDialog';

import useCRUD from './hooks/useCRUD';
import useGetJSON from 'utils/hooks/useGetJSON';
import { ROLES } from 'constants.js';
import CustomDebugDialog from 'components/CustomDebugDialog/CustomDebugDialog';

function EmployeeTable(props) {
    const {
        operator,
        http,
        google,
        onSnackbar,
        theme,
        twoFactorAuthenticationWarning,
        rolePermissions,
        collector,
        pickupsEnabled
    } = props;

    const endpoints = {
        getEndPoint: '/users/getCRUDUsers',
        getEditEndPoint: _id => `/users/${_id}/adminUpdate`,
        createEndPoint: `/users/adminCreateUser`
    };

    const [collectors, setCollectors] = useState([]);
    const [collectorsLoading, setCollectorsLoading] = useState(false);
    const [processors, setProcessors] = useState([]);
    const [processorsLoading, setProcessorsLoading] = useState(false);

    const [driverConfig, setDriverConfig] = useState({});
    const [driverConfigLoading, setDriverConfigLoading] = useState(false);

    const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);
    const [disableAccountDialogOpen, setDisableAccountDialogOpen] = useState(false);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');

    const [promptForDriverCode, setPromptForDriverCode] = useState(false);
    const [statusMenuEL, setStatusMenuEL] = useState(null);
    const [actionsMenuEL, setActionsMenuEL] = useState(null);

    const [debugDialogOpen, setDebugDialogOpen] = useState(false);
    const [debugDialogJSON, setDebugDialogJSON] = useState({});

    const {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit,
        handleReloadData
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const dataUpdated = data.map(document => {
        const clonedDocument = _.cloneDeep(document);
        if (clonedDocument.accountType === 'Collector Employee' && clonedDocument.collector) {
            clonedDocument.collectorsParsed = clonedDocument.collector.name;
        } else if (clonedDocument.accountType === 'Collector Administrator' && clonedDocument.collectors) {
            clonedDocument.collectorsParsed = clonedDocument.collectors
                .map(collector => (collector ? collector.name : null))
                .filter(name => name !== null);
        } else {
            clonedDocument.collectorsParsed = '';
        }

        return clonedDocument;
    });

    const { loading: commoditiesLoading, error: commoditiesLoadingErr, data: commodities } = useGetJSON(
        `/commodities/getAllCommodities`,
        'collectionData'
    );

    /*const { loading: collectorsLoading, error: collectorError, data: collectors = [] } = useGetJSON(
        '/collectors?includeDisabled=true',
        'collectors'
    );*/

    useEffect(() => {
        (async () => {
            setCollectorsLoading(true);
            const res = await http.getJSON('/collectors?includeDisabled=true');
            if (res.ok) {
                setCollectors(res.data.collectors);
            }
            setCollectorsLoading(false);
        })();
        (async () => {
            setProcessorsLoading(true);
            const res = await http.getJSON('/collectors/all');
            if (res.ok) {
                setProcessors(res.data.collectors);
            }
            setProcessorsLoading(false);
        })();
        (async () => {
            setDriverConfigLoading(true);
            const res = await http.getJSON('/users/getDriversConfig');
            if (res.ok) {
                setDriverConfig(res.data);
            }
            setDriverConfigLoading(false);
        })();
    }, []);

    const handleReenableDriverAccount = async () => {
        await handleReloadData();
        setSelectedDocument(_.find(data, d => d._id.toString() === selectedDocument._id.toString()));
        setEditDialogOpen(true);
    };

    useEffect(() => {
        if (promptForDriverCode) {
            handleReenableDriverAccount();
        }
    }, [disableAccountDialogOpen]);

    useEffect(() => {
        if (!editDialogOpen) {
            setPromptForDriverCode(false);
        }
    }, [editDialogOpen]);

    const handleStatusMenuClick = (e, employee) => {
        setSelectedDocument(employee);
        setStatusMenuEL(e.currentTarget);
    };

    const handleStatusMenuClose = () => {
        setSelectedDocument(null);
        setStatusMenuEL(null);
    };

    const handleActionsMenuClick = (e, employee) => {
        setSelectedDocument(employee);
        setActionsMenuEL(e.currentTarget);
    };

    const handleActionsMenuClose = () => {
        setSelectedDocument(null);
        setActionsMenuEL(null);
    };

    const onEditRow = document => {
        setSelectedDocument(document);
        setEditDialogOpen(true);
        setActionsMenuEL(null);
    };

    const onViewJSON = document => {
        setActionsMenuEL(null);
        setDebugDialogJSON(document);
        setDebugDialogOpen(true);
    };
    const onDebugDialogClose = () => {
        setDebugDialogOpen(false);
    };

    const onViewAccount = document => {
        setActionsMenuEL(null);
        if (ROLES.includes(operator.accountType) && rolePermissions) {
            if (
                (_user.isDriver(document) || _user.isClerk(document)) &&
                !_.get(rolePermissions, 'accounts.driver', false)
            ) {
                onSnackbar('You do not have permissions to view driver accounts', 'error');
                return;
            } else if (
                document.accountType === 'Collector Administrator' &&
                !_.get(rolePermissions, 'accounts.collector', false)
            ) {
                onSnackbar('You do not have permissions to view collector accounts', 'error');
                return;
            } else if (
                document.accountType === 'System Administrator' &&
                !_.get(rolePermissions, 'accounts.systemAdministrator', false)
            ) {
                onSnackbar('You do not have permissions to view system administrator accounts', 'error');
                return;
            }
        }
        let extension = '';
        if (_user.isDriver(document) || _user.isClerk(document)) {
            extension = 'driver';
        } else if (document.accountType === 'Collector Administrator') {
            extension = 'collector';
        }
        if (deviceHelper.isNativeApp()) {
            props.history.push(`/operators/${document._id}/${extension}`);
        } else {
            window.open(`/operators/${document._id}/${extension}`, '_blank');
        }
    };

    const onLogoutUser = async user => {
        setStatusMenuEL(null);
        setLoading(true);
        const res = await http.post(`/users/${user._id}/adminLogoutUser`);
        setLoading(false);
        if (res.ok) {
            onSnackbar('User logged out successfully');
        }
    };

    const onPasswordResetDialog = document => {
        setStatusMenuEL(null);
        setSelectedDocument(document);
        setPasswordResetDialogOpen(true);
    };

    const onDisableAccountDialog = document => {
        setStatusMenuEL(null);
        setSelectedDocument(document);
        setDisableAccountDialogOpen(true);
    };

    const systemAdminColumns = [
        { key: 'name.first', header: 'First Name' },
        { key: 'name.last', header: 'Last Name' },
        { key: 'driverCode', header: 'Code' },
        { key: 'email', header: 'Email' },
        { key: 'accountType', header: 'Type' },
        { key: 'accountPermissions', header: 'Permissions' },
        { key: 'phone', header: 'Phone', isPhoneNumber: true },
        { key: 'collectorsParsed', header: 'Collectors' },
        { key: 'driverDestination.code', header: 'Destination' },
        { key: 'depotLoginID', header: 'User ID' },
        { key: 'lastActivity', header: 'Last Login', isDate: true },
        // {
        //     key: 'pickupRating',
        //     header: 'Pickup Accuracy',
        //     formatValue: value => (
        //         <SingleRatingWidget
        //             goodCount={_.get(value, 'countGoodPickups', null)}
        //             totalCount={_.get(value, 'totalPickups', null)}
        //             tooltipText="Total pickups without complaints/Total pickups"
        //             noTotalTooltip="No Pickups completed"
        //         />
        //     ),
        //     toSortValue: value => {
        //         let total = value.totalPickups;
        //         total = total ? total : 1; //set to one if total pickups is null or 0

        //         return _.get(value, 'countGoodPickups', 0) / total;
        //     }
        // },
        // {
        //     key: 'countRating',
        //     header: 'Sorting Accuracy',
        //     formatValue: value => (
        //         <SingleRatingWidget
        //             goodCount={_.get(value, 'goodCounts', null)}
        //             totalCount={_.get(value, 'totalCounts', null)}
        //             tooltipText="Total bulks counted without complaints/Total bulks counted"
        //             noTotalTooltip="No bulks counted"
        //         />
        //     ),
        //     toSortValue: value => {
        //         let total = value.totalCounts;
        //         total = total ? total : 1; //set to one if total counts is null or 0

        //         return _.get(value, 'goodCounts', 0) / total;
        //     }
        // },
        {
            key: 'startTime',
            header: 'Start Time',
            formatValue: value => (value ? moment(value).format('h:mm a') : value)
        },
        {
            key: 'home.description',
            header: 'Start location'
        },
        {
            key: 'driverBagCapacity',
            header: 'Bag Capacity'
        },
        {
            key: 'driverPickupCapacity',
            header: 'Capacity'
        },
        {
            key: 'wage',
            header: 'Wage',
            formatValue: value => (value ? formatAsCurrency(value) : value)
        },
        {
            key: 'kpl',
            header: 'Fuel Efficiency'
        },
        {
            key: 'gasCost',
            header: 'Gas Cost',
            formatValue: value => (value ? formatAsCurrency(value) : value)
        },
        {
            key: 'permissions.seeAllPendingPickups',
            header: 'See All Pickups',
            isBoolean: true
        },
        {
            key: 'permissions.seeCustomerPhoneNumbers',
            header: "See Phone #'s",
            isBoolean: true
        }
    ];

    const defaultColumnFiltersAdmin = [
        'name.first',
        'name.last',
        'driverCode',
        'email',
        'accountType',
        'accountPermissions',
        'phone',
        'collectorsParsed',
        'driverDestination.code',
        'depotLoginID',
        'googleDirectionCalls',
        'lastActivity'
        // 'pickupRating',
        // 'countRating'
    ];

    const collectorColumns = [
        { key: 'name.first', header: 'First Name' },
        { key: 'name.last', header: 'Last Name' },
        { key: 'driverCode', header: 'Code' },
        { key: 'driverDestination.code', header: 'Destination' },
        { key: 'depotLoginID', header: 'User ID' },
        { key: 'email', header: 'Email' },
        { key: 'accountType', header: 'Type' },
        { key: 'accountPermissions', header: 'Permissions' },
        { key: 'phone', header: 'Phone', isPhoneNumber: true },
        { key: 'lastActivity', header: 'Last Login', isDate: true },
        // {
        //     key: 'pickupRating',
        //     header: 'Pickup Accuracy',
        //     formatValue: value => (
        //         <SingleRatingWidget
        //             goodCount={_.get(value, 'countGoodPickups', null)}
        //             totalCount={_.get(value, 'totalPickups', null)}
        //             tooltipText="Total pickups without complaints/Total pickups"
        //             noTotalTooltip="No Pickups completed"
        //         />
        //     ),
        //     toSortValue: value => {
        //         let total = value.totalPickups;
        //         total = total ? total : 1; //set to one if total pickups is null or 0

        //         return _.get(value, 'countGoodPickups', 0) / total;
        //     }
        // },
        // {
        //     key: 'countRating',
        //     header: 'Sorting Accuracy',
        //     formatValue: value => (
        //         <SingleRatingWidget
        //             goodCount={_.get(value, 'goodCounts', null)}
        //             totalCount={_.get(value, 'totalCounts', null)}
        //             tooltipText="Total bulks counted without complaints/Total bulks counted"
        //             noTotalTooltip="No bulks counted"
        //         />
        //     ),
        //     toSortValue: value => {
        //         let total = value.totalCounts;
        //         total = total ? total : 1; //set to one if total counts is null or 0

        //         return _.get(value, 'goodCounts', 0) / total;
        //     }
        // },
        {
            key: 'startTime',
            header: 'Start Time',
            formatValue: value => (value ? moment(value).format('h:mm a') : value)
        },
        {
            key: 'home.description',
            header: 'Start location'
        },
        {
            key: 'driverBagCapacity',
            header: 'Bag Capacity'
        },
        {
            key: 'driverPickupCapacity',
            header: 'Capacity'
        },
        {
            key: 'wage',
            header: 'Wage',
            formatValue: value => (value ? formatAsCurrency(value) : value)
        },
        {
            key: 'kpl',
            header: 'Fuel Efficiency'
        },
        {
            key: 'gasCost',
            header: 'Gas Cost',
            formatValue: value => (value ? formatAsCurrency(value) : value)
        },
        {
            key: 'permissions.seeAllPendingPickups',
            header: 'See All Pickups',
            isBoolean: true
        },
        {
            key: 'permissions.seeCustomerPhoneNumbers',
            header: "See Phone #'s",
            isBoolean: true
        }
    ];

    const defaultColumnFiltersCollector = [
        'name.first',
        'name.last',
        'driverCode',
        'email',
        'accountType',
        'driverDestination.code',
        'depotLoginID',
        'accountPermissions',
        'phone',
        'collectorsParsed',
        'lastActivity',
        // 'pickupRating',
        // 'countRating',
        'driverBagCapacity'
    ];

    const editForm = (
        <UserForm
            user={selectedDocument}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            http={http}
            driverConfig={driverConfig}
            setLoading={setLoading}
            loading={loading || collectorsLoading || driverConfigLoading || processorsLoading}
            editing={!_.isNil(selectedDocument)}
            onSnackbar={onSnackbar}
            operator={operator}
            open={editDialogOpen}
            onClose={
                promptForDriverCode
                    ? () => {}
                    : () => {
                          setPromptForDriverCode(false);
                          setEditDialogOpen(false);
                      }
            }
            collectors={collectors}
            processors={processors}
            commodities={commodities}
            google={google}
            twoFactorAuthenticationWarning={twoFactorAuthenticationWarning}
            promptForDriverCode={promptForDriverCode}
            accountTypes={['Collector Administrator', 'Collector Employee']}
            pickupsEnabled={pickupsEnabled}
        />
    );
    const disabled =
        ROLES.includes(operator.accountType) &&
        document.accountType !== 'Collector Employee' &&
        !_.get(rolePermissions, 'accounts.systemAdministrator', false);
    const renderExtraActions = document => {
        return (
            <>
                <Tooltip title="Actions">
                    <IconButton
                        data-cy={`employee-table-actions-button`}
                        onClick={e => {
                            handleActionsMenuClick(e, document);
                        }}
                    >
                        <Icon>tune</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Update Account Status">
                    <IconButton
                        data-cy={`${document.banned ? 'banned-' : ''}employee-table-status-button`}
                        onClick={e => {
                            handleStatusMenuClick(e, document);
                        }}
                    >
                        <MDIcon path={mdiAccountKey} size={1} color={theme.palette.text.secondary} />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={
                    operator.accountType === 'System Administrator' || ROLES.includes(operator.accountType)
                        ? systemAdminColumns
                        : collectorColumns
                }
                data={dataUpdated}
                editForm={editForm}
                renderExtraActions={renderExtraActions}
                documentIsDisabled={employee => _.get(employee, 'banned', false)}
                defaultRowsPerPage={5}
                startDateFilterStats={startDateFilterStats}
                setStartDateFilterStats={setStartDateFilterStats}
                endDateFilterStats={endDateFilterStats}
                setEndDateFilterStats={setEndDateFilterStats}
                dateFilterStatsErrorMessage={dateFilterStatsErrorMessage}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Enabled Accounts'}
                disabledHeaderText={'Banned Accounts'}
                loading={loading || collectorsLoading}
                defaultColumnFilters={
                    operator.accountType === 'System Administrator' || ROLES.includes(operator.accountType)
                        ? defaultColumnFiltersAdmin
                        : defaultColumnFiltersCollector
                }
                hideDateFilters
                initialCollectorFilter={
                    collector && _user.isCollectorAdmin(operator) && _.get(operator, 'collectors', []).length > 1
                        ? collector
                        : null
                }
                hideEditButton={true}
                hideViewJSON={true}
            />
            <PasswordResetDialog
                open={passwordResetDialogOpen}
                onClose={() => setPasswordResetDialogOpen(false)}
                user={selectedDocument}
                http={http}
                onSnackbar={onSnackbar}
            />
            {disableAccountDialogOpen && (
                <DisableAccountDialog
                    selectedAccount={selectedDocument}
                    allEmployees={data}
                    open={disableAccountDialogOpen}
                    onClose={() => {
                        setDisableAccountDialogOpen(false);
                    }}
                    loading={loading || collectorsLoading}
                    setLoading={setLoading}
                    onReloadData={handleReloadData}
                    http={http}
                    setPromptForDriverCode={setPromptForDriverCode}
                />
            )}
            {!_.isNil(selectedDocument) && (
                <Menu
                    data-cy="collector-edit-zones-btn"
                    anchorEl={statusMenuEL}
                    open={Boolean(statusMenuEL)}
                    onClose={handleStatusMenuClose}
                >
                    {!selectedDocument.banned ? (
                        <MenuItem
                            onClick={() => onDisableAccountDialog(selectedDocument)}
                            data-cy="employee-table-ban-button"
                            disabled={disabled}
                        >
                            <ListItemIcon>
                                <MDIcon
                                    path={mdiAccountOff}
                                    size={1}
                                    color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                                />
                            </ListItemIcon>
                            <ListItemText primary={'Ban Account'} />
                        </MenuItem>
                    ) : (
                        <MenuItem
                            onClick={() => onDisableAccountDialog(selectedDocument)}
                            data-cy="employee-table-enable-button"
                            disabled={disabled}
                        >
                            <ListItemIcon>
                                <MDIcon
                                    path={mdiAccountCheck}
                                    size={1}
                                    color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                                />
                            </ListItemIcon>
                            <ListItemText primary={'Unban Account'} />
                        </MenuItem>
                    )}

                    <MenuItem
                        onClick={() => onPasswordResetDialog(selectedDocument)}
                        data-cy="employee-table-reset-password"
                        disabled={disabled}
                    >
                        <ListItemIcon>
                            <MDIcon
                                path={mdiLockReset}
                                size={1}
                                color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                            />
                        </ListItemIcon>
                        <ListItemText primary={'Reset Password'} />
                    </MenuItem>

                    <MenuItem
                        onClick={() => onLogoutUser(selectedDocument)}
                        disabled={disabled}
                        data-cy="employee-table-logout"
                    >
                        <ListItemIcon>
                            <MDIcon
                                path={mdiLogout}
                                size={1}
                                color={disabled ? 'rgba(0, 0, 0, 0.24)' : theme.palette.text.secondary}
                            />
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </MenuItem>
                </Menu>
            )}
            {!_.isNil(selectedDocument) && (
                <Menu
                    data-cy="actions-menu"
                    anchorEl={actionsMenuEL}
                    open={Boolean(actionsMenuEL)}
                    onClose={handleActionsMenuClose}
                >
                    <MenuItem
                        onClick={() => {
                            onViewAccount(selectedDocument);
                        }}
                        data-cy="employee-table-view-account"
                        disabled={disabled}
                    >
                        <ListItemIcon>
                            <Icon>visibility</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'View Account'} />
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            onViewJSON(selectedDocument);
                        }}
                        data-cy="employee-table-view-json"
                        disabled={disabled}
                    >
                        <ListItemIcon>
                            <MDIcon path={mdiCodeJson} size={1} color={theme.palette.text.secondary} />
                        </ListItemIcon>
                        {/* <ListItemText primary={'View JSON'} /> */}
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            onEditRow(selectedDocument);
                        }}
                        data-cy="employee-table-edit-button"
                        // disabled={!_.isNil(documentNotEditable) ? documentNotEditable(document) : false}
                    >
                        <ListItemIcon>
                            <Icon>edit</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'Edit'} />
                    </MenuItem>
                </Menu>
            )}
            <CustomDebugDialog open={debugDialogOpen} json={debugDialogJSON} onClose={onDebugDialogClose} />
        </>
    );
}

export default withTheme()(EmployeeTable);
