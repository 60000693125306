import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment-timezone';
import _ from 'lodash';
import { _promo, _charity, _user } from 'std';

import { Icon as MDIcon } from '@mdi/react';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import { mdiTagTextOutline, mdiCheckDecagram } from '@mdi/js';

import * as terms from 'localizations/terms';

import * as colors from '@material-ui/core/colors';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc, locDate } from 'localizations/localizationHandler';

import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Button,
    InputAdornment,
    TextField,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';

import HttpContext from 'utils/contexts/HttpContext';

const PromoCodesInput = props => {
    const http = useContext(HttpContext);
    const { lang } = useContext(LocalizationContext);

    const {
        customer,
        promos,
        theme,
        name = 'default',
        label = loc('promoCode', lang),
        disabled,
        allowSystemPromos,
        extraAdornments,
        onRemovePromo,
        onApplyPromo,
        bypassNewCustomersCheck = false,
        charityPreferred,
        donate,
        onCharityPreferred,
        isLastPickupStep,
        donationDialogTitle,
        donationDialogText,
        hideSearch = false
    } = props;

    const [inProgress, setInProgress] = useState(false);
    const [promoCodeValue, setPromoCodeValue] = useState('');
    const [error, setError] = useState(false);
    const [errorDescription, setErrorDescription] = useState(undefined);
    // const [conditionsDialogOpen, setConditionsdialogOpen] = useState(false);

    const [quickSelectPromos, setQuickSelectPromos] = useState([]);
    const [quickSelectPromoDialogOpen, setQuickSelectPromoDialogOpen] = useState(false);

    const [lastPromo, setLastPromo] = useState(null);

    const [charityUpdateDialogOpen, setCharityUpdateDialogOpen] = useState(null);
    const [charityRestrictedPromo, setCharityRestrictedPromo] = useState({});

    const [oneDayOnlyDialogOpen, setOneDayOnlyDialogOpen] = useState(false);
    const [removeOneDayPromo, setRemoveOneDayPromo] = useState(null);

    const handleKeyPress = e => {
        if (e.key === 'Enter' && !_.isEmpty(promoCodeValue)) {
            handleApply();
        }
    };

    const handlePromoCode = e => {
        setPromoCodeValue(e.target.value.toUpperCase().replace(/[^a-z0-9&]/gi, ''));
    };

    const handleSearch = async () => {
        const permissions = ['Collector Administrator'];
        const res = await http.getJSON(`/promos?permissions=${permissions}`);
        if (res.ok) {
            setQuickSelectPromos(_.get(res, 'data.promos', []));
            setQuickSelectPromoDialogOpen(true);
        }
    };

    const handleApply = async value => {
        const promoCodeVal = _.isEmpty(value) ? promoCodeValue : value;
        setQuickSelectPromoDialogOpen(false);

        if (promos.length >= 1) {
            setError(true);
            setErrorDescription(loc('promoInput1', lang));
        }

        setInProgress(true);
        const res = await http.postJSON(`/promos/validate/${JSON.stringify(promoCodeVal)}`, {
            customer_id: _.get(customer, '_id', null),
            allowSystemPromos,
            bypassNewCustomersCheck,
            charityToBeDonated: donate ? charityPreferred : null
        });

        if (res.ok) {
            const result = _.get(res, 'data.result', {});
            const promo = _.get(res, 'data.promo');

            const promoErrorDate = locDate(result.date, 'MMM Do YYYY', lang);
            const errorReasonStr = loc(result.reasonId, lang, { date: promoErrorDate });

            const oneDayOnly = _.get(promo, 'oneDayOnly', false);
            if (result.valid && oneDayOnly) {
                setLastPromo(promo);
                setOneDayOnlyDialogOpen(true);
                setError(false);
                setErrorDescription(undefined);
                setInProgress(false);
                return;
            }

            if (result.valid) {
                setLastPromo(promo);
                setError(false);
                setErrorDescription(undefined);
                onApplyPromo(promo);
            } else {
                if (result.reasonId === 'charityDonationOnly') {
                    if (!promo || !promo.charity) return;

                    setCharityRestrictedPromo(promo);
                    setCharityUpdateDialogOpen(true);
                }

                setError(true);
                setErrorDescription(`${loc('pickupDialog59', lang)} ${errorReasonStr}.`);
            }
        }

        setInProgress(false);
    };

    const handleRemovePromo = promo => {
        const oneDayOnly = _.get(promo, 'oneDayOnly', false);
        if (oneDayOnly && isLastPickupStep) {
            setRemoveOneDayPromo(promo);
            return;
        }

        setPromoCodeValue('');
        onRemovePromo(promo);
    };

    const applyLastPromo = () => {
        onApplyPromo(lastPromo);
    };

    const handleCharityUpdate = () => {
        setCharityUpdateDialogOpen(false);

        if (!charityRestrictedPromo || !charityRestrictedPromo.charity) return;

        setError(false);
        setErrorDescription(undefined);

        onCharityPreferred(charityRestrictedPromo.charity);
        setLastPromo(charityRestrictedPromo);
        onApplyPromo(charityRestrictedPromo);
    };

    useEffect(() => {
        const lastPromoCharity = _.get(lastPromo, 'charity._id');
        if (!_.isNil(lastPromoCharity) && lastPromoCharity.toString() !== charityPreferred && !_.isEmpty(promos)) {
            promos.forEach(promo => {
                onRemovePromo(promo);
            });
            setPromoCodeValue('');
            setLastPromo(null);
        }
    }, [charityPreferred]);

    // NB: since we only allow one promo code at a time at the moment, we just hide the textfield when one is entered already
    return (
        <>
            {_.isEmpty(promos) ? (
                <TextField
                    data-cy={`promo-codes-input-${name}`}
                    fullWidth
                    type="text"
                    variant="outlined"
                    name={name}
                    label={label}
                    value={promoCodeValue}
                    error={error}
                    helperText={errorDescription}
                    disabled={disabled || inProgress}
                    style={{ marginBottom: 1, marginTop: theme.spacing.unit * 2 }}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                        style: {
                            backgroundColor: theme.palette.background.paper
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <MDIcon path={mdiTagTextOutline} size={1} color={theme.palette.text.secondary} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {extraAdornments}
                                {!hideSearch && _.get(customer, 'adminView', false) && (
                                    <IconButton
                                        disabled={inProgress || disabled}
                                        edge="end"
                                        aria-label="Delete"
                                        onClick={handleSearch}
                                    >
                                        <SearchIcon color="action" />
                                    </IconButton>
                                )}

                                <Button
                                    color="primary"
                                    variant="outlined"
                                    disabled={disabled || _.isEmpty(promoCodeValue) || inProgress}
                                    onClick={() => handleApply()}
                                    style={{ height: 32, minWidth: 48 }}
                                    data-cy="promo-codes-input-apply-button"
                                >
                                    {inProgress ? <CircularProgress size={16} /> : loc('apply', lang)}
                                </Button>
                            </InputAdornment>
                        )
                    }}
                    onChange={handlePromoCode}
                />
            ) : (
                <List style={{ padding: 0 }}>
                    {promos.map((promo, index) => {
                        const refundPercentage = _.get(promo, 'variables.customerSplit', 0) * 100;

                        return (
                            <ListItem
                                key={promo.code}
                                style={{
                                    marginTop: theme.spacing.unit * 2,
                                    // paddingTop: theme.spacing.unit * 0.875,
                                    // paddingBottom: theme.spacing.unit * 0.5,
                                    paddingLeft: 14,
                                    backgroundColor: colors.green[100],
                                    borderRadius: 4,
                                    width: 270
                                }}
                            >
                                <MDIcon
                                    path={mdiCheckDecagram}
                                    size={1}
                                    color={colors.green[700]}
                                    style={{ minWidth: '1.5rem' }}
                                />
                                <ListItemText
                                    style={{ paddingLeft: theme.spacing.unit * 2 }}
                                    primary={
                                        <span>
                                            {/* {loc('growthPostInfo4', lang)}:{' '} */}
                                            <span style={{ fontWeight: 500 }}>{promo.code}</span>
                                        </span>
                                    }
                                    secondary={
                                        <>
                                            <span>{promo.description}</span>{' '}
                                            {_promo.requireFeatured(promo) && refundPercentage > 0 && (
                                                <span>({refundPercentage}%)</span>
                                            )}
                                        </>
                                    }
                                    // secondary={
                                    //     <>
                                    //         <span>{promo.description}</span>{' '}
                                    //         {_promo.requireFeatured(promo) && (
                                    //             <span>
                                    //                 (
                                    //                 <span
                                    //                     style={{
                                    //                         color: theme.palette.linkColor,
                                    //                         cursor: 'pointer',
                                    //                         textDecoration: 'underline'
                                    //                     }}
                                    //                     onClick={() => setConditionsdialogOpen(true)}
                                    //                 >
                                    //                     {loc('pickupDialog61', lang)}
                                    //                 </span>
                                    //                 )
                                    //             </span>
                                    //         )}
                                    //     </>
                                    // }
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        data-cy="promo-codes-input-remove"
                                        disabled={inProgress || disabled}
                                        edge="end"
                                        aria-label="Delete"
                                        onClick={() => handleRemovePromo(promo)}
                                    >
                                        <DeleteIcon color="action" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            )}
            <Dialog
                open={quickSelectPromoDialogOpen}
                onClose={() => setQuickSelectPromoDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>{loc('promoInput2', lang)}</DialogTitle>
                <DialogContent>
                    <List>
                        {quickSelectPromos.map(promo => {
                            return (
                                <ListItem
                                    key={promo.code}
                                    style={{
                                        paddingTop: theme.spacing.unit * 0.5,
                                        paddingBottom: theme.spacing.unit * 0.5,
                                        paddingLeft: 14,
                                        backgroundColor: colors.green[100],
                                        borderRadius: 4,
                                        marginBottom: theme.spacing.unit
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <span>
                                                {loc('growthPostInfo4', lang)}:{' '}
                                                <span style={{ fontWeight: 500 }}>{promo.code}</span>
                                            </span>
                                        }
                                        secondary={promo.description}
                                    />
                                    <ListItemSecondaryAction>
                                        <Button
                                            disabled={inProgress || disabled}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => handleApply(promo.code)}
                                        >
                                            {loc('use', lang)}
                                        </Button>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog open={charityUpdateDialogOpen} onClose={() => setCharityUpdateDialogOpen(false)} fullWidth>
                <DialogTitle>{donationDialogTitle ? donationDialogTitle : loc('promoInput4', lang, {})}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {donationDialogText
                            ? donationDialogText
                            : loc('promoInput5', lang, { charityName: _.get(charityRestrictedPromo, 'charity.name') })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => handleCharityUpdate()}
                        data-cy="promo-donation-dialog-confirm"
                    >
                        {loc('promoInput7', lang, { charityName: _.get(charityRestrictedPromo, 'charity.name') })}
                    </Button>
                    <Button onClick={() => setCharityUpdateDialogOpen(false)}>{loc('back', lang)}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={oneDayOnlyDialogOpen} onClose={() => setOneDayOnlyDialogOpen(false)} fullWidth>
                <DialogTitle>{loc('promoInput8', lang, {})}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {loc('promoInput9', lang, { date: moment(_.get(lastPromo, 'startDate')).format('LL') })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            applyLastPromo();
                            setOneDayOnlyDialogOpen(false);
                        }}
                    >
                        {loc('yes', lang)}
                    </Button>
                    <Button onClick={() => setOneDayOnlyDialogOpen(false)}>{loc('no', lang)}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={!_.isNil(removeOneDayPromo)} onClose={() => setRemoveOneDayPromo(null)} fullWidth>
                <DialogTitle>{loc('promoInput10', lang)}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {loc('promoInput11', lang, {
                            date: moment(_.get(removeOneDayPromo, 'startDate')).format('LL')
                        })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            setPromoCodeValue('');
                            onRemovePromo(removeOneDayPromo, { returnToDateSelect: true });
                            setRemoveOneDayPromo(null);
                        }}
                    >
                        {loc('yes', lang)}
                    </Button>
                    <Button onClick={() => setRemoveOneDayPromo(null)}>{loc('no', lang)}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default withTheme()(PromoCodesInput);
