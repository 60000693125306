import { useState, useEffect, useContext, useMemo } from 'react';
import { getTotalAmount, formatAsCurrency } from 'utils/misc';

import { REPORT_TYPES } from 'constants.js';

import _ from 'lodash';
import { _bulk } from 'std';
import { _charity, _commodity, _user } from 'std';

import useImageUpload from 'components/ImageUploads/useImageUpload';
import useGetJSON from 'utils/hooks/useGetJSON';

import HttpContext from 'utils/contexts/HttpContext';
import OperatorContext from 'utils/contexts/OperatorContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import useSpeedMode from './useSpeedMode';
import { getComEquivalent } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import { NON_CONFORMANT_COMPLAINT_CODE } from '../../../../constants';

// TODO: break this up into smaller hooks
function usePORScreen({
    location,
    match,
    history,
    commodities,
    onEmitCountsUpdated,
    onEmitSwitchedBulk,
    setShowStreamPopup,
    recheckApproval
}) {
    const operator = useContext(OperatorContext);
    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    // states set from the API return
    const [bulk, setBulk] = useState(null);
    const [bulkSkus, setBulkSkus] = useState(null); //skus from the rates stored on the bulk

    const [customerHistory, setCustomerHistory] = useState([]);

    const [bulkStationConfig, setBulkStationConfig] = useState([]);
    const [allBulksFromSameCustomer, setAllBulksFromSameCustomer] = useState([]); //NOTE: its now only the last 10 bulks #2892
    const [allManualBulksFromSameCustomer, setAllManualBulksFromSameCustomer] = useState([]);
    const [collectors, setCollectors] = useState([]);
    const [collector, setCollector] = useState(null);

    // bulk loading states
    const [bulkDataLoading, setBulkDataLoading] = useState(true);
    const [bulkDataLoadingError, setBulkDataLoadingError] = useState(null);
    const [currentCounts, setCurrentCounts] = useState([]);

    const [inProgress, setInProgress] = useState(true);
    const [countForm, setCountForm] = useState(null);
    const [customFeesForm, setCustomFeesForm] = useState([]);
    const [promoCodes, setPromoCodes] = useState([]);
    const [customerIssueResolution, setCustomerIssueResolution] = useState('');

    const [reportType, setReportType] = useState(REPORT_TYPES.CUSTOMER);
    const [reportDialogOpen, setReportDialogOpen] = useState(false);

    const [driverReportReasons, setDriverReportReasons] = useState([]);
    const [customerReportReasons, setCustomerReportReasons] = useState([]);
    const [nonConformantWarning, setNonConformantWarning] = useState(false);

    const [customerReportIssueDescription, setCustomerReportIssueDescription] = useState('');

    const [driverReportIssueDescription, setDriverReportIssueDescription] = useState('');

    const [customFeeDialogOpen, setCustomFeeDialogOpen] = useState(false);
    const [customFeeDialogCount, setCustomFeeDialogCount] = useState(1);

    const [complaintDialogOpen, setComplaintDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [numberOfBagsDialogOpen, setNumberOfBagsDialogOpen] = useState(false);
    const [editNumberOfBagsDialogOpen, setEditNumberOfBagsDialogOpen] = useState(false);
    const [createEditNumberOfCommoditiesDialogOpen, setCreateEditNumberOfCommoditiesDialogOpen] = useState(false);
    const [uncountedBagsDialogOpen, setUncountedBagsDialogOpen] = useState(false);
    const [creatEditNumberOfCommoditiesMode, setCreatEditNumberOfCommoditiesMode] = useState('edit');

    const [attachCustomerDialogOpen, setAttachCustomerDialogOpen] = useState(false);
    const [customerInfo, setCustomerInfo] = useState('');
    const { speedMode } = useSpeedMode();

    const [showLAFImageDialog, setShowLAFImageDialog] = useState(false);
    const [showAICountImageDialog, setShowAICountImageDialog] = useState(false);

    const [aiCountsConfig, setAICountsConfig] = useState({});

    const { loading: adjustmentsLoading, data: adjustmentConfig = {} } = useGetJSON(
        '/bulks/adjustmentConfig',
        'adjustments'
    );

    const {
        imageURLs: lafImages,
        imagePreviews: lafPreviews,
        setImagePreviews: setLafPreviews,
        handleDeleteImage: handleDeleteLafImage,
        handleAddImage: handleAddLafImage
    } = useImageUpload({});

    const {
        imageURLs: driverReportImages,
        imagePreviews: driverReportPreviews,
        setImagePreviews: setDriverReportPreviews,
        handleDeleteImage: handleDeleteDriverImage,
        handleAddImage: handleAddDriverImage
    } = useImageUpload({});

    const {
        imageURLs: customerReportImages,
        imagePreviews: customerReportPreviews,
        setImagePreviews: setCustomerReportPreviews,
        handleDeleteImage: handleDeleteCustomerImage,
        handleAddImage: handleAddCustomerImage
    } = useImageUpload({});

    const {
        imageURLs: AICountImages,
        imagePreviews: AICountPreviews,
        setImagePreviews: setAICountPreviews,
        handleDeleteImage: handleDeleteAICountImage,
        handleAddImage: handleAddAICountImage
    } = useImageUpload({ singleMode: true });

    const commodityForBulk = useMemo(() => _.find(commodities, { skuType: _.get(bulk, 'skuType', '') }), [
        bulk,
        commodities
    ]);

    const bulkType = _.get(bulk, 'bulkType');
    const permissionsForBulk = _.get(collector, `configuration.bulkPermissions.${bulkType}`, {});

    const nonConformantIssue = NON_CONFORMANT_COMPLAINT_CODE;

    const [countingSessions, setCountingSessions] = useState(_.get(bulk, 'countingSessions', []));
    const [currentCountingSessionId, setCurrentCountingSessionId] = useState('');
    // hook for starting new counting session

    useEffect(() => {
        if (_.isNil(bulk)) return;
        if (_bulk.isCompleted(bulk) || _bulk.isInvoiced(bulk)) return;
        setCountingSessions(_.get(bulk, 'countingSessions', []));
    }, [bulk]);

    useEffect(async () => {
        const res = await http.getJSON('/aiCounts/config');

        if (res.ok) {
            setAICountsConfig(_.get(res, 'data.config'));
        }
    }, []);

    // fetches collectors, rates (anything that isnt on the with the bulk)
    const fetchBulkCounterData = async () => {
        const res = await http.getJSON(`/users/getPORScreenData/${operator._id}`, false, false);
        if (res.ok) {
            setCollectors(_.get(res, 'data.collectors', []));
        } else {
            setBulkDataLoadingError('ERROR LOADING');
        }
    };

    // fetches bulk and history bulks
    const fetchBulkData = async (setBulkCounterConfig = false) => {
        const res = await http.getJSON(`/bulks/getBulk/${_.get(match, 'params.bulk_id', [])}`, false, false);
        if (res.ok) {
            setAllDataFromResponse(res, setBulkCounterConfig);
        } else {
            setBulkDataLoadingError('ERROR LOADING');
        }

        return { bulk: _.get(res, 'data.bulk', null), currentCounts: _.get(res, 'data.bulk.counts', []) };
    };

    const fetchAllData = async () => {
        setBulkDataLoading(true);
        const { bulk: newBulkData } = await fetchBulkData(true);
        fetchBulkCounterData();

        onEmitSwitchedBulk(newBulkData);

        setInProgress(false);

        setBulkDataLoading(false);
        // clears any counts when entering page, fetchBulkData() will not trigger this, but a page refresh would
        localStorage.removeItem('currentBulkCounts');
    };

    useEffect(() => {
        fetchAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const setAllDataFromResponse = (res, setBulkCounterConfig = false) => {
        setBulk(_.get(res, 'data.bulk', null));
        setCurrentCounts(_.get(res, 'data.bulk.counts', []));
        setCustomFeesForm(_.get(res, 'data.bulk.customFees', {}));
        setPromoCodes(_.get(res, 'data.bulk.promosApplied', {}));
        setCollector(_.get(res, 'data.bulk.collector', {}));
        setAllBulksFromSameCustomer(_.get(res, 'data.allBulksFromSameCustomer', []));

        setCustomerReportReasons(_.get(res, 'data.bulk.payloadIssues.issues', []));
        setCustomerReportPreviews(_.get(res, 'data.bulk.payloadIssues.images', []));
        setCustomerReportIssueDescription(_.get(res, 'data.bulk.payloadIssues.description', ''));

        setDriverReportReasons(_.get(res, 'data.bulk.driverIssues.issues', []));
        setDriverReportPreviews(_.get(res, 'data.bulk.driverIssues.images', []));
        setDriverReportIssueDescription(_.get(res, 'data.bulk.driverIssues.description', ''));
        setCustomerIssueResolution(_.get(res, 'data.bulk.customerIssues.resolution', ''));

        const bulkSkus = _.groupBy(_.get(res, 'data.bulk.rates.rates', []), 'skuType');
        setBulkSkus(bulkSkus);

        const currentBulkCounts = JSON.parse(localStorage.getItem('currentBulkCounts'));

        if (!_.isNil(currentBulkCounts) && !_.isEmpty(currentBulkCounts)) {
            setCountForm(currentBulkCounts);
        } else {
            setCountForm(setInitialCountForm(bulkSkus[_.get(res, 'data.bulk.skuType', null)]));
        }
        // dont always need to set it each time, we can assume its synced up properly
        if (setBulkCounterConfig) {
            const config = _.get(res, 'data.bulkStationConfig', []);
            let skusForBulk = bulkSkus[_.get(res, 'data.bulk.skuType', null)];
            if (!_.isEmpty(config) && !_.isEmpty(skusForBulk)) {
                skusForBulk = skusForBulk.map(s => s.sku);
                const updatedConfig = _(config)
                    .filter(c => {
                        return skusForBulk.includes(c.sku) || c.special;
                    })
                    .value(); // filter out any skus that arent on the current skus
                setBulkStationConfig(updatedConfig);
            }
        }
    };

    const fetchCustomerHistory = async customerId => {
        const res = await http.getJSON(`/getCustomerHistory/${customerId}`, false, false);
        if (res.ok) {
            setCustomerHistory(res.data.customerHistory);
        }
    };

    useEffect(() => {
        if (!bulk || !bulk.owner) return;

        const owner = _.get(bulk, 'owner._id', bulk.owner);
        fetchCustomerHistory(owner);
    }, [bulk]);

    const handleCustomFeeDialog = (state, count = 1) => {
        if (state && (_.isEmpty(currentCountingSessionId) || _.isNil(currentCountingSessionId))) {
            onSnackbar('Please select or create a counting session first', 'error');
            return;
        }
        setCustomFeeDialogOpen(state);
        setCustomFeeDialogCount(count);
    };

    const handleReportReasonsChange = name => e => {
        let reportReasons = reportType === REPORT_TYPES.DRIVER ? driverReportReasons : customerReportReasons;

        let newReasons = _.clone(reportReasons);
        if (_.indexOf(reportReasons, name) === -1) {
            newReasons.push(name);
        } else {
            newReasons = _.filter(reportReasons, reason => {
                return reason !== name;
            });
        }

        if (reportType === REPORT_TYPES.DRIVER) {
            setDriverReportReasons(newReasons);
        } else {
            setCustomerReportReasons(newReasons);
        }
    };

    const handleAddImage = (reportImageFile, reportImageFilePreview) => {
        if (reportType === REPORT_TYPES.DRIVER) {
            handleAddDriverImage(reportImageFile, reportImageFilePreview);
        } else {
            handleAddCustomerImage(reportImageFile, reportImageFilePreview);
        }
    };

    const handleDeleteImage = idx => {
        if (reportType === REPORT_TYPES.DRIVER) {
            handleDeleteDriverImage(idx);
        } else {
            handleDeleteCustomerImage(idx);
        }
    };

    const handleApplyPromo = async promo => {
        setInProgress(true);
        const updatedPromos = _.concat(promoCodes, promo);
        const res = await http.post(`/bulks/${bulk._id}/updatePromos`, { promos: updatedPromos }, true);

        if (res.ok) {
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleRemovePromo = async (promo, isLostAndFound) => {
        setInProgress(true);
        const updatedPromos = _.filter(promoCodes, p => p.code !== promo.code);
        const res = await http.post(
            `/bulks/${bulk._id}/updatePromos`,
            { promos: updatedPromos, isLostAndFound: isLostAndFound },
            true
        );

        if (res.ok) {
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setInProgress(false);
    };

    const handleCountFormChange = e => {
        const material = e.target.id.toString();
        const sku = parseInt(e.target.name, 10);
        const value = parseInt(e.target.value, 10);

        let updatedCountForm = _.cloneDeep(countForm);
        const containerGroup = updatedCountForm[material];
        for (let container of containerGroup) {
            if (container.sku === sku) {
                container.quantity = value || 0;
                break;
            }
        }
        localStorage.setItem('currentBulkCounts', JSON.stringify(updatedCountForm));
        setCountForm(updatedCountForm);
    };

    const handleCreateBulk = async () => {
        setBulkDataLoading(true);

        const bulkTypeToCreate = bulk.bulkType === 'pickup' ? 'adjustment' : bulk.bulkType;

        const res = await http.postJSON(
            `/bulks/create`,
            {
                skuType: bulk.skuType,
                bulkType: bulkTypeToCreate,
                customer_id: _.get(bulk, 'owner._id'),
                promosApplied: bulk.promosApplied,
                commodityAmount: 1
            },
            true
        );

        if (res.ok) {
            onSnackbar('Created new bulk!');
            history.push(`/operators/${operator._id}/bulks/${res.data.bulk_id}`);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setBulkDataLoading(false);
    };

    const handleChangeCollector = async e => {
        const collector_id = e.target.value;
        setInProgress(true);

        const res = await http.post(`/bulks/${bulk._id}/updateCollector`, { collector_id }, true);
        if (res.ok) {
            fetchBulkData(true);
            onSnackbar(`Collector updated!`);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setInProgress(false);
    };

    const handleApplyCustomFee = async (customFee, count = 1) => {
        setInProgress(true);
        console.log('THIS', customFee, count);
        const res = await http.postJSON(`/bulks/${bulk._id}/applyFee`, { customFee, count }, true);

        if (res.ok) {
            let newFeeId = _.get(res, 'data.newFeeId', null);
            let newCountingSessions = _.cloneDeep(countingSessions);
            let customFee2 = _.cloneDeep(_.get(res, 'data.fee', customFee));
            customFee2._id = newFeeId;
            customFee2.quantity = count;
            // customFee2.amount = count * customFee2.amount;
            let currentCountingSession = _.find(newCountingSessions, { _id: currentCountingSessionId });
            if (!_.isNil(currentCountingSession)) {
                currentCountingSession.customFees = _.get(currentCountingSession, 'customFees', []).concat(customFee2);
                const res2 = await http.postJSON(
                    `/bulks/${bulk._id}/updateCountingSessions`,
                    { countingSession: currentCountingSession },
                    true
                );
                if (res2.ok) {
                    onEmitCountsUpdated(bulk);
                    setAllDataFromResponse(res2);
                    setCountingSessions(newCountingSessions);

                    setCustomFeeDialogOpen(false);
                    fetchBulkData();
                    onSnackbar('Fees updated!');
                } else {
                    onSnackbar(res2.errorMessage, 'error');
                }
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleRemoveCustomFee = async index => {
        setInProgress(true);
        const res = await http.post(`/bulks/${bulk._id}/removeFee`, { index }, true);

        if (res.ok) {
            onEmitCountsUpdated(bulk);
            setCustomFeeDialogOpen(false);
            fetchBulkData();
            onSnackbar('Fees updated!');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleRemoveCustomFeeFromSessionAndBulk = async (sessionId, feeId) => {
        setInProgress(true);
        const res = await http.post(
            `/bulks/${bulk._id}/removeFeeFromSessionAndBulk/${sessionId}/fee/${feeId}`,
            {},
            true
        );

        if (res.ok) {
            onEmitCountsUpdated(bulk);
            setCustomFeeDialogOpen(false);
            fetchBulkData();
            onSnackbar('Fees updated!');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleCloseReportDialog = () => {
        setNonConformantWarning(false);
        setReportDialogOpen(false);

        setTimeout(() => {
            setReportType(REPORT_TYPES.CUSTOMER);
        }, 200);
    };

    const handleOpenReportDialog = type => {
        setReportType(type); // Driver or customer
        setReportDialogOpen(true);
    };

    const handleToggleComplete = async () => {
        setInProgress(true);

        if (!_bulk.isCompleted(bulk)) {
            const res = await http.postJSON(`/bulks/${bulk._id}/complete`, {}, true);
            if (res.ok) {
                if (_bulk.isAdjustment(bulk)) {
                    onSnackbar('Successfully submitted adjustment bulk!');
                    fetchBulkData();
                } else {
                    const extraParam = speedMode ? '?openSearchDialog=true?clearSearchFilter=true' : '';
                    history.push(`/operators/${operator._id}/bulks${extraParam}`);
                    if (res.data.message) {
                        onSnackbar(res.data.message);
                    }
                }
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        } else {
            const res = await http.post(`/bulks/${bulk._id}/reopen`, {}, true);
            if (res.ok) {
                setNumberOfBagsDialogOpen(true);
                fetchBulkData();
            }
        }

        setInProgress(false);
    };

    const handleReportIssueDescription = e => {
        if (reportType === REPORT_TYPES.DRIVER) {
            setDriverReportIssueDescription(e.target.value);
        } else {
            setCustomerReportIssueDescription(e.target.value);
        }
    };

    const handleSubmitReport = async () => {
        setInProgress(true);

        let reportReasons = reportType === REPORT_TYPES.DRIVER ? driverReportReasons : customerReportReasons;
        let reportImages = reportType === REPORT_TYPES.DRIVER ? driverReportImages : customerReportImages;
        let reportPreviews = reportType === REPORT_TYPES.DRIVER ? driverReportPreviews : customerReportPreviews;
        let reportIssueDescription =
            reportType === REPORT_TYPES.DRIVER ? driverReportIssueDescription : customerReportIssueDescription;

        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log

        if (reportType === REPORT_TYPES.DRIVER || (_.isEmpty(reportImages) && !_.isEmpty(reportPreviews))) {
            formData.append('noImageUpload', true);
        } else {
            reportImages.forEach(reportImage => {
                formData.append('issueImages', reportImage);
            });
        }

        // Handles deleting old images
        reportPreviews.forEach(reportPreview => {
            formData.append('issuePreviews', JSON.stringify(reportPreview));
        });

        formData.append('issues', JSON.stringify(reportReasons));
        formData.append('description', JSON.stringify(reportIssueDescription));

        const res = await http.post(`/bulks/${bulk._id}/report?reportType=${reportType}`, formData, true, true);

        if (res.ok) {
            onSnackbar('Bulk was reported!');
            fetchBulkData();
            setNonConformantWarning(false);
            setReportDialogOpen(false);

            setTimeout(() => {
                setReportType(REPORT_TYPES.CUSTOMER);
            }, 200);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        setInProgress(false);
    };

    const handleUndoBulkReport = async () => {
        setInProgress(true);

        const formData = new FormData(); // NB: FormData objects cannot be displayed in console.log
        formData.append('undoReport', true);
        formData.append('issues', JSON.stringify([]));
        formData.append('description', JSON.stringify(null));

        const res = await http.post(`/bulks/${bulk._id}/report?reportType=${reportType}`, formData, true, true);
        if (res.ok) {
            fetchBulkData();
            onSnackbar('Removed order report');
            setNonConformantWarning(false);
            setReportDialogOpen(false);

            setTimeout(() => {
                setReportType(REPORT_TYPES.CUSTOMER);
            }, 200);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleToggleResolveCustomerIssue = async () => {
        setInProgress(true);

        const isResolved = _.isNil(_.get(bulk, 'customerIssues.dateResolved', null));

        const res = await http.post(
            `/bulks/${bulk._id}/resolve`,
            { isResolved, customerIssueResolution: isResolved ? customerIssueResolution : '' },
            true
        );

        if (res.ok) {
            fetchBulkData();
            if (isResolved) {
                setComplaintDialogOpen(false);
            }
            onSnackbar('Successfully resolved customer issue');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleRemoveCounts = async (count_id, item_id) => {
        setInProgress(true);
        const res = await http.post(`/bulks/${bulk._id}/removeCountV2/${count_id}/item/${item_id}`, {}, true);

        const countingSessions = _.find(_.get(bulk, 'countingSessions', []), { _id: count_id });
        const items = _.get(countingSessions, 'items', []);
        const item = _.find(items, { _id: item_id });
        const quantity = _.get(item, 'quantity', 0);
        if (res.ok) {
            onEmitCountsUpdated(bulk);
            onSnackbar(`Removed ${quantity} containers`);
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
            fetchBulkData();
        }

        setInProgress(false);
    };

    const handleRemoveSessionlessCounts = async (count_id, item_id) => {
        setInProgress(true);
        const res = await http.post(`/bulks/${bulk._id}/removeCount/${count_id}/item/${item_id}`, {}, true);

        const countObj = _.find(_.get(bulk, 'counts', []), { _id: count_id });
        const items = _.get(countObj, 'items', []);
        const item = _.find(items, { _id: item_id });
        const quantity = _.get(item, 'quantity', 0);
        if (res.ok) {
            onEmitCountsUpdated(bulk);
            onSnackbar(`Removed ${quantity} containers`);
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
            fetchBulkData();
        }

        setInProgress(false);
    };

    const handleSubmitCounts = async () => {
        if (
            allCommoditiesCounted(bulk, commodities) &&
            (_.isEmpty(currentCountingSessionId) || _.isNil(currentCountingSessionId))
        ) {
            onSnackbar('Please select or create a counting session first', 'error');
            return;
        }
        setInProgress(true);

        const counts = rollupCounts(countForm);
        let hasNonConformant = false;
        counts.forEach(count => {
            if (count.sku === 9010) {
                hasNonConformant = true;
            }
        });

        // response returns all necessary data, so no need to re pull the data
        const res = await http.postJSON(`/bulks/${bulk._id}/count`, { counts }, true);
        if (res.ok) {
            let newItemList = _.get(res, 'data.newItemList', []);
            let newCountingSessions = _.cloneDeep(countingSessions);
            let counts2 = _.cloneDeep(newItemList);
            counts2.forEach((count, index) => {
                counts2[index].dateCounted = new Date();
            });
            let currentCountingSession = _.find(newCountingSessions, { _id: currentCountingSessionId });
            if (!_.isNil(currentCountingSession)) {
                currentCountingSession.items = _.get(currentCountingSession, 'items', []).concat(counts2);
            } else {
                currentCountingSession = {
                    operator: operator._id,
                    items: counts2,
                    dateCounted: new Date(),
                    commodityCounted: []
                };
            }
            const res2 = await http.postJSON(
                `/bulks/${bulk._id}/updateCountingSessions`,
                { countingSession: currentCountingSession },
                true
            );
            if (res2.ok) {
                onEmitCountsUpdated(bulk);
                setAllDataFromResponse(res2);
                let newBulk = _.get(res2, 'data.bulk', null);
                if (_.isNil(currentCountingSessionId) || _.isEmpty(currentCountingSessionId)) {
                    setCurrentCountingSessionId(_.get(res2, 'data.newSessionId', ''));
                }
                setCountingSessions(_.get(newBulk, 'countingSessions', []));

                const oneType = counts.length === 1;
                const total = getTotalAmount(countForm);
                const quantity = counts.reduce((sum, val) => (sum += val.quantity), 0);

                localStorage.removeItem('currentBulkCounts');
                if (_.isEmpty(counts)) {
                    onSnackbar('Closed out order with empty counts');
                } else {
                    onSnackbar(
                        `Added ${quantity} ${oneType ? ` x ${counts[0].size}` : ''}${
                            oneType ? ` ${counts[0].materialType} ` : ' '
                        }containers - ${formatAsCurrency(total)}`
                    );
                }
                setCountForm(setInitialCountForm(bulkSkus[bulk.skuType])); // clear counts form
            } else {
                onSnackbar(res2.errorMessage, 'error');
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        if (
            hasNonConformant &&
            (!customerReportReasons.includes(nonConformantIssue) || _.isNil(customerReportPreviews))
        ) {
            setCustomerReportReasons([...customerReportReasons, nonConformantIssue]);
            setNonConformantWarning(true);
            setReportDialogOpen(true);
        }

        setInProgress(false);
    };

    const handleClearCountsAndFees = async () => {
        // response returns all necessary data, so no need to re pull the data
        const res = await http.postJSON(`/bulks/${bulk._id}/clearCountsAndFees`, {}, true);
        if (res.ok) {
            onEmitCountsUpdated(bulk);
            setAllDataFromResponse(res);
            onSnackbar('Successfully cleared order counts and fees!');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    // fetches bulk and history bulks
    const handleDeleteBulk = async () => {
        setInProgress(true);
        const res = await http.postJSON(`/bulks/${bulk._id}/delete`, {}, true);
        if (res.ok) {
            const { bulk_id } = res.data;

            setBulkDataLoading(true);
            history.push(`/operators/${operator._id}/bulks`);
            onSnackbar('Order was successfully deleted!');
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setBulkDataLoading(false);
        setInProgress(false);
    };

    const handleAddCount = async (amount, sku) => {
        if (
            allCommoditiesCounted(bulk, commodities) &&
            (_.isEmpty(currentCountingSessionId) || _.isNil(currentCountingSessionId))
        ) {
            onSnackbar('Please select or create a counting session first', 'error');
            return;
        }
        setInProgress(true);
        let success = false;
        const counts = [
            {
                materialType: sku.material,
                size: sku.label,
                sku: sku.sku,
                quantity: parseInt(amount)
            }
        ];
        // response returns all necessary data, so no need to re pull the data
        const res = await http.postJSON(`/bulks/${bulk._id}/count`, { counts }, true);
        if (res.ok) {
            const total = Math.trunc(Math.abs(parseInt(amount) * sku.value));
            onSnackbar(`Added ${amount} x ${sku.label} ${sku.description} containers - ${formatAsCurrency(total)}`);
            success = true;
            let newItemList = _.get(res, 'data.newItemList', []);
            let newCountingSessions = _.cloneDeep(countingSessions);
            let counts2 = _.cloneDeep(newItemList);
            counts2.forEach((count, index) => {
                counts2[index].dateCounted = new Date();
            });
            let currentCountingSession = _.find(newCountingSessions, { _id: currentCountingSessionId });
            if (!_.isNil(currentCountingSession)) {
                currentCountingSession.items = _.get(currentCountingSession, 'items', []).concat(counts2);
            } else {
                currentCountingSession = {
                    operator: operator._id,
                    items: counts2,
                    dateCounted: new Date(),
                    commodityCounted: []
                };
            }
            const res2 = await http.postJSON(
                `/bulks/${bulk._id}/updateCountingSessions`,
                { countingSession: currentCountingSession },
                true
            );
            if (res2.ok) {
                onEmitCountsUpdated(bulk);
                setAllDataFromResponse(res2);
                let newBulk = _.get(res2, 'data.bulk', null);
                if (_.isNil(currentCountingSessionId) || _.isEmpty(currentCountingSessionId)) {
                    setCurrentCountingSessionId(_.get(res2, 'data.newSessionId', ''));
                }
                setCountingSessions(_.get(newBulk, 'countingSessions', []));
                recheckApproval(_.get(res2, 'data.bulk', null));
            } else {
                onSnackbar(res2.errorMessage, 'error');
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        if (
            sku.material === 'NON' &&
            (!customerReportReasons.includes(nonConformantIssue) || _.isNil(customerReportPreviews))
        ) {
            setCustomerReportReasons([...customerReportReasons, nonConformantIssue]);
            setNonConformantWarning(true);
            setReportDialogOpen(true);
        }

        setInProgress(false);
        return success;
    };

    const handleAddAICounts = async countsArg => {
        if (
            allCommoditiesCounted(bulk, commodities) &&
            (_.isEmpty(currentCountingSessionId) || _.isNil(currentCountingSessionId))
        ) {
            onSnackbar('Please select or create a counting session first', 'error');
            return;
        }

        setInProgress(true);

        const counts = [];

        let success = false;
        let hasNonConformant = false;

        countsArg.forEach(count => {
            let { amount, sku, countImageUrl } = count;

            if (sku.sku === 9010) {
                hasNonConformant = true;
            }

            counts.push({
                materialType: sku.material,
                size: sku.label,
                sku: sku.sku,
                quantity: parseInt(amount),
                countImageUrl: countImageUrl
            });
        });

        // response returns all necessary data, so no need to re pull the data
        const res = await http.postJSON(`/bulks/${bulk._id}/count`, { counts }, true);
        if (res.ok) {
            success = true;

            let newItemList = _.get(res, 'data.newItemList', []);
            let counts2 = _.cloneDeep(newItemList);
            counts2.forEach((count, index) => {
                counts2[index].dateCounted = new Date();
            });

            const countingSession = {
                operator: operator._id,
                items: counts2,
                dateCounted: new Date(),
                commodityCounted: []
            };

            const res2 = await http.postJSON(
                `/bulks/${bulk._id}/addCountingSessionItems`,
                { countingSessionId: currentCountingSessionId, countingSession },
                true
            );

            if (res2.ok) {
                onEmitCountsUpdated(bulk);
                setAllDataFromResponse(res2);

                if (_.isNil(currentCountingSessionId) || _.isEmpty(currentCountingSessionId)) {
                    setCurrentCountingSessionId(_.get(res2, 'data.newSessionId', ''));
                }

                let newBulk = _.get(res2, 'data.bulk', null);
                setCountingSessions(_.get(newBulk, 'countingSessions', []));
            } else {
                onSnackbar(res2.errorMessage, 'error');
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }

        if (
            hasNonConformant &&
            (!customerReportReasons.includes(nonConformantIssue) || _.isNil(customerReportPreviews))
        ) {
            setCustomerReportReasons([...customerReportReasons, nonConformantIssue]);
            setNonConformantWarning(true);
            setReportDialogOpen(true);
        }

        setInProgress(false);
        return success;
    };

    const handleChangeCustomerIssueResolution = e => {
        setCustomerIssueResolution(e.target.value);
    };

    const handleViewBulk = bulk_id => {
        // setBulkDataLoading(true);
        history.push(`/operators/${operator._id}/bulks/${bulk_id}`);
        // setTimeout(() => {
        //     setBulkDataLoading(false);
        // }, 1);
    };

    const handleShowErrorDialog = state => {
        setErrorDialogOpen(state);
    };

    const handleOpenComplaintDialog = () => {
        setComplaintDialogOpen(true);
    };

    const handleCloseComplaintDialog = () => {
        setComplaintDialogOpen(false);
    };

    const handleSetCharity = async charity => {
        setInProgress(true);
        const res = await http.post(`/bulks/${bulk._id}/setCharity`, { charityId: _charity.getId(charity) });
        if (res.ok) {
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };
    const handleRemoveCharity = async () => {
        setInProgress(true);
        const res = await http.post(`/bulks/${bulk._id}/setCharity`, { charityId: null });
        if (res.ok) {
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleOpenAttachCustomer = () => {
        setAttachCustomerDialogOpen(true);
    };

    const handleCloseAttachCustomer = () => {
        setAttachCustomerDialogOpen(false);
        setCustomerInfo('');
    };

    const handleCloseNumberOfBagsDialog = () => {
        setNumberOfBagsDialogOpen(false);
    };
    const handleCloseEditNumberOfBagsDialog = () => {
        setEditNumberOfBagsDialogOpen(false);
    };

    const handleChangeCustomerInfo = e => {
        setCustomerInfo(e.target.value);
    };

    const handleAttachCustomer = async () => {
        const res = await http.post(`/bulks/${bulk._id}/attachCustomer`, { customerInfo }, true);
        if (res.ok) {
            onSnackbar('Successfully attached a customer to this bulk!');
            setAttachCustomerDialogOpen(false);
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    const handleDetachCustomer = async () => {
        const res = await http.post(`/bulks/${bulk._id}/detachCustomer`, {}, true);
        if (res.ok) {
            onSnackbar('Successfully detached a customer from this bulk!');
            setAttachCustomerDialogOpen(false);
            fetchBulkData();
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    };

    const handleUpdateCommodityProcessed = async amountToAddOrRemove => {
        setInProgress(true);
        const res = await http.postJSON(`/bulks/${bulk._id}/updateCommoditiesProcessed`, { amountToAddOrRemove }, true);
        if (res.ok) {
            onSnackbar(`Updated counted ${bulk.commodityUOM}s!`);
            setAttachCustomerDialogOpen(false);
            setAllDataFromResponse(res);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleUpdateCommodityAmount = async (commodityAmount, commoditiesProcessed) => {
        setInProgress(true);
        const res = await http.postJSON(
            `/bulks/${bulk._id}/updateCommodityAmount`,
            { commodityAmount, commoditiesProcessed },
            true
        );
        if (res.ok) {
            onSnackbar(`Updated total ${bulk.commodityUOM}s!`);
            setAttachCustomerDialogOpen(false);
            setAllDataFromResponse(res);
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
        setInProgress(false);
    };

    const handleUpdateCommoditesProccessedBreakdown = async commoditiesProcessedBreakdown => {
        if (_.isEmpty(commoditiesProcessedBreakdown)) return;
        setInProgress(true);
        let newCountingSessions = _.cloneDeep(countingSessions);
        if (!_.isNil(currentCountingSessionId)) {
            newCountingSessions.forEach((item, index) => {
                if (item._id === currentCountingSessionId) {
                    let commodityCountedChangeMap = {};

                    newCountingSessions[index].commodityCounted.forEach(count => {
                        if (!count.isSubCommodity) {
                            commodityCountedChangeMap['main'] = count;
                        } else {
                            commodityCountedChangeMap[count.subCommodity] = count;
                        }
                    });
                    commoditiesProcessedBreakdown.forEach(count => {
                        if (!count.isSubCommodity) {
                            if (commodityCountedChangeMap['main'] === undefined) {
                                commodityCountedChangeMap['main'] = count;
                            } else {
                                if (commodityCountedChangeMap['main'].amount + count.amount === 0) {
                                    delete commodityCountedChangeMap.main;
                                } else {
                                    commodityCountedChangeMap['main'].amount += count.amount;
                                }
                            }
                        } else {
                            if (commodityCountedChangeMap[count.subCommodity] === undefined) {
                                commodityCountedChangeMap[count.subCommodity] = count;
                            } else {
                                if (commodityCountedChangeMap[count.subCommodity].amount + count.amount === 0) {
                                    delete commodityCountedChangeMap[count.subCommodity];
                                } else {
                                    commodityCountedChangeMap[count.subCommodity].amount += count.amount;
                                }
                            }
                        }
                    });
                    newCountingSessions[index].commodityCounted = Object.values(commodityCountedChangeMap);
                }
            });
        }
        let currentCountingSession = _.find(newCountingSessions, { _id: currentCountingSessionId });
        if (_.isNil(currentCountingSession)) {
            currentCountingSession = {
                operator: operator._id,
                items: [],
                dateCounted: new Date(),
                commodityCounted: commoditiesProcessedBreakdown
            };
        }
        const res2 = await http.postJSON(
            `/bulks/${bulk._id}/updateCountingSessions`,
            { countingSession: currentCountingSession },
            true
        );
        if (res2.ok) {
            onEmitCountsUpdated(bulk);
            setAllDataFromResponse(res2);
            if (_.isNil(currentCountingSessionId) || _.isEmpty(currentCountingSessionId)) {
                setCurrentCountingSessionId(_.get(res2, 'data.newSessionId', ''));
            }
            setCountingSessions(_.get(bulk, 'countingSessions', []));
            const res = await http.postJSON(
                `/bulks/${bulk._id}/updateCommoditiesProcessedBreakdown`,
                { commoditiesProcessedBreakdown: commoditiesProcessedBreakdown },
                true
            );
            if (res.ok) {
                onSnackbar(`Updated processed commodities!`);
                setAttachCustomerDialogOpen(false);
                setAllDataFromResponse(res);
                recheckApproval(_.get(res, 'data.bulk', null));
            } else {
                onSnackbar(res.errorMessage, 'error');
            }
        } else {
            onSnackbar(res2.errorMessage, 'error');
        }
        setInProgress(false);
    };

    async function addEmptySession() {
        const res = await http.postJSON(
            `/bulks/${bulk._id}/updateCountingSessions`,
            {
                countingSession: {
                    operator: operator._id,
                    items: [],
                    dateCounted: new Date(),
                    commodityCounted: []
                }
            },
            true
        );
        if (res.ok) {
            setAllDataFromResponse(res);
            setCurrentCountingSessionId(_.get(res, 'data.newSessionId', ''));
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    }

    const handleUpdateAdjustmentReason = async adjustmentReason => {
        const res = await http.postJSON(`/bulks/${bulk._id}/adjustmentReason`, { adjustmentReason });
        if (res.ok) {
            let newBulk = _.cloneDeep(bulk);
            newBulk.adjustmentReason = _.get(res, 'data.bulk.adjustmentReason', '');
            setBulk(newBulk);
        }
    };
    const handleUpdateReferenceNote = async referenceNote => {
        const res = await http.postJSON(`/bulks/${bulk._id}/referenceNote`, { referenceNote });
        if (res.ok) {
            let newBulk = _.cloneDeep(bulk);
            newBulk.referenceNote = _.get(res, 'data.bulk.referenceNote', '');
            setBulk(newBulk);
        }
    };

    const handleRemoveCountingSession = async sessionId => {
        setInProgress(true);
        const res2 = await http.postJSON(`/bulks/${bulk._id}/removeCountingSession/${sessionId}`, {}, true);
        if (res2.ok) {
            onSnackbar(`Session removed`);
            onEmitCountsUpdated(bulk);
            setAllDataFromResponse(res2);
            setCurrentCountingSessionId('');
        } else {
            onSnackbar(res2.errorMessage, 'error');
        }
        setInProgress(false);
    };

    useEffect(() => {
        if (!bulkDataLoading && !_.isNil(bulk)) {
            if (bulk.isLostAndFound) {
                if (!_.isEmpty(bulk.lostAndFoundImages)) {
                    conditionalOpenNumberOfBagsDialog();
                }
            } else {
                conditionalOpenNumberOfBagsDialog();
            }
        }
    }, [_.get(bulk, '_id', ''), bulkDataLoading, commodityForBulk]);

    useEffect(() => {
        // only do for collector admin and employees
        if (
            (operator.accountType === 'Collector Administrator' && !operator.adminView) ||
            (operator.accountType === 'Collector Employee' &&
                !operator.adminView &&
                operator.accountPermissions.includes('Clerk'))
        ) {
            let manualCommodities = Array.from(
                commodities.filter(commodity => commodity.completionStrategy === 'Manual'),
                item => item.skuType
            );
            let newAllBulksFromSameCustomer = allBulksFromSameCustomer.filter(bulk =>
                manualCommodities.includes(bulk.skuType)
            );
            setAllManualBulksFromSameCustomer(newAllBulksFromSameCustomer);
        } else {
            setAllManualBulksFromSameCustomer(allBulksFromSameCustomer);
        }
    }, [allBulksFromSameCustomer, commodities]);

    useEffect(() => {
        if (
            !_.isNil(bulk) &&
            bulk.isLostAndFound &&
            _.isEmpty(bulk.lostAndFoundImages) &&
            (bulk.promptImgUpload || _.isNil(bulk.promptImgUpload))
        ) {
            setShowLAFImageDialog(true);
            http.postJSON(`/bulks/${bulk._id}/disableImageUploadPrompt`, {
                promptImgUpload: false
            });
        }
    }, [_.get(bulk, '_id', ''), bulkDataLoading]);

    const handleLAFImageSubmit = async () => {
        if (_.isEmpty(lafImages)) return;

        let images = [];

        const formData = new FormData();
        lafImages.forEach(lafImage => {
            formData.append('images', lafImage);
        });

        const res = await http.postJSON(`/system/uploadMultipleImages`, formData, false, true);
        images = _.get(res, 'data.imageUrls', []);

        const res1 = await http.postJSON(`/bulks/${bulk._id}/setLostAndFoundImages`, {
            filePaths: images
        });

        if (res1.ok) {
            fetchBulkData();
            onSnackbar('Lost and found image succesfully updated!');
        }

        handleCloseLAFImageDialog();
    };
    const handleCloseLAFImageDialog = () => {
        setShowLAFImageDialog(false);
        conditionalOpenNumberOfBagsDialog();
    };
    const handleOpenLAFImageDialog = () => {
        setShowLAFImageDialog(true);
    };

    function conditionalOpenNumberOfBagsDialog() {
        const disableBagDailogSysAdmin = _bulk.isCompleted(bulk);
        const disableBagDailogOtherAccountTypes =
            _bulk.isCompleted(bulk) ||
            !_commodity.isManuallyCompleted(commodityForBulk) ||
            !_.get(permissionsForBulk, 'update', false);
        const disableBagDailog =
            _user.isSystemAdmin(operator) || _user.isInternalRole(operator)
                ? disableBagDailogSysAdmin
                : disableBagDailogOtherAccountTypes;
        if (!bulkDataLoading) {
            if (disableBagDailog) {
                return;
            } else {
                setNumberOfBagsDialogOpen(true);
            }
        }
    }
    async function updateCountBags(countIds, bags) {
        await http.post(`/bulks/${bulk._id}/updateCountBags`, {
            countIds: countIds,
            bags: bags
        });
    }

    async function createNewCountingSession(commodityCounted = []) {
        let newCountingSession = {
            operator: operator._id,
            items: [],
            dateCounted: new Date(),
            commodityCounted: commodityCounted
        };
        const res = await http.postJSON(
            `/bulks/${bulk._id}/updateCountingSessions`,
            { countingSession: newCountingSession },
            true
        );
        if (res.ok) {
            onSnackbar('New session created');
            setAllDataFromResponse(res);
            let newBulk = _.get(res, 'data.bulk', null);
            setCountingSessions(_.get(newBulk, 'countingSessions', []));
            setCurrentCountingSessionId(_.get(res, 'data.newSessionId'));

            const res2 = await http.postJSON(
                `/bulks/${bulk._id}/updateCommoditiesProcessedBreakdown`,
                { commoditiesProcessedBreakdown: commodityCounted },
                true
            );
            if (res2.ok) {
                setAllDataFromResponse(res2);
            }
        } else {
            onSnackbar(res.errorMessage, 'error');
        }
    }
    return {
        bulk,
        bulkSkus,
        customerHistory,
        countForm,
        customFeesForm,
        inProgress,
        promoCodes,
        bulkDataLoading,
        collector,
        collectors,
        customFeeDialogOpen,
        customFeeDialogCount,
        customerIssueResolution,
        currentCounts,
        complaintDialogOpen,
        errorDialogOpen,
        reportDialogOpen,
        reportType,
        reportReasons: reportType === REPORT_TYPES.DRIVER ? driverReportReasons : customerReportReasons,
        reportImages: reportType === REPORT_TYPES.DRIVER ? driverReportImages : customerReportImages,
        reportPreviews: reportType === REPORT_TYPES.DRIVER ? driverReportPreviews : customerReportPreviews,
        reportIssueDescription:
            reportType === REPORT_TYPES.DRIVER ? driverReportIssueDescription : customerReportIssueDescription,
        bulkDataLoadingError,
        bulkStationConfig,
        allBulksFromSameCustomer: allManualBulksFromSameCustomer,
        attachCustomerDialogOpen,
        customerInfo,
        numberOfBagsDialogOpen,
        nonConformantIssue,
        nonConformantWarning,
        commodityForBulk,
        permissionsForBulk,
        aiCountsConfig,
        showLAFImageDialog,
        lafImages,
        lafPreviews,
        adjustmentConfig,
        setLafPreviews,
        handleAddLafImage,
        handleDeleteLafImage,
        editNumberOfBagsDialogOpen,
        setEditNumberOfBagsDialogOpen,
        uncountedBagsDialogOpen,
        setUncountedBagsDialogOpen,
        handleReportIssueDescription,
        handleCreateBulk,
        handleToggleComplete,
        handleCustomFeeDialog,
        handleChangeCollector,
        handleReportReasonsChange,
        handleAddImage,
        handleDeleteImage,
        handleSubmitReport,
        handleCloseReportDialog,
        handleOpenReportDialog,
        handleApplyCustomFee,
        handleRemoveCustomFee,
        handleCountFormChange,
        handleRemoveCounts,
        handleViewBulk,
        handleSubmitCounts,
        handleApplyPromo,
        handleRemovePromo,
        handleUndoBulkReport,
        handleToggleResolveCustomerIssue,
        handleChangeCustomerIssueResolution,
        handleAddCount,
        handleOpenComplaintDialog,
        handleCloseComplaintDialog,
        handleShowErrorDialog,
        handleClearCountsAndFees,
        handleDeleteBulk,
        handleSetCharity,
        handleOpenAttachCustomer,
        handleCloseAttachCustomer,
        handleCloseNumberOfBagsDialog,
        handleChangeCustomerInfo,
        handleAttachCustomer,
        handleUpdateCommodityProcessed,
        handleUpdateCommodityAmount,
        fetchBulkData,
        handleLAFImageSubmit,
        handleCloseLAFImageDialog,
        handleRemoveCharity,
        handleOpenLAFImageDialog,
        handleCloseEditNumberOfBagsDialog,
        updateCountBags,
        handleUpdateCommoditesProccessedBreakdown,
        handleUpdateAdjustmentReason,
        countingSessions,
        setCountingSessions,
        currentCountingSessionId,
        setCurrentCountingSessionId,
        createEditNumberOfCommoditiesDialogOpen,
        setCreateEditNumberOfCommoditiesDialogOpen,
        handleRemoveCountingSession,
        handleRemoveSessionlessCounts,
        handleRemoveCustomFeeFromSessionAndBulk,
        handleDetachCustomer,
        showAICountImageDialog,
        AICountImages,
        AICountPreviews,
        setAICountPreviews,
        handleDeleteAICountImage,
        handleAddAICountImage,
        setShowAICountImageDialog,
        handleAddAICounts,
        createNewCountingSession,
        creatEditNumberOfCommoditiesMode,
        setCreatEditNumberOfCommoditiesMode,
        addEmptySession,
        handleUpdateReferenceNote
    };
}

export default usePORScreen;

function setInitialCountForm(skus) {
    const updatedSkus = _.filter(skus, sku => sku.countable).map(sku => {
        sku.quantity = 0;
        return sku;
    });

    const newCountForm = _.groupBy(updatedSkus, sku => sku.material);
    return newCountForm;
}

function rollupCounts(countForm) {
    let counts = [];
    _.keys(countForm).forEach(material => {
        countForm[material].forEach(container => {
            if (container.quantity > 0) {
                counts.push({
                    sku: container.sku,
                    materialType: container.description,
                    size: container.label, // TODO: rework this
                    quantity: container.quantity
                });
            }
        });
    });
    return counts;
}

function allCommoditiesCounted(bulk, commodities) {
    let numOfProccessedSubCom = getProcessedNumOfSubCom(bulk);
    let numOfSubCom = getNumOfSubCom(bulk);
    let numOfSortedBags = _.get(
        _.find(_.get(bulk, 'commoditiesProcessedBreakdown', []), { isSubCommodity: false }),
        'amount',
        0
    );
    let numOfSubComEquivalent = getComEquivalent(bulk, commodities);
    let numOfReportedBags = _.get(bulk, 'commodityAmount', 0) - numOfSubComEquivalent;
    let totalAmount = numOfSubCom + Math.max(numOfReportedBags, numOfSortedBags);
    return numOfProccessedSubCom >= totalAmount;
}

function getNumOfSubCom(bulk) {
    const subPayloads = _.get(bulk, 'pickup.subPayloads', []);
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    let commodityMap = {};
    let sum = 0;
    for (let subPayload of subPayloads) {
        let subcomId = _.get(subPayload, `subCommodity`, '');
        commodityMap[subcomId] = _.get(subPayload, `amount`, 0);
    }
    for (let subPayload of commoditiesProcessedBreakdown) {
        if (_.get(subPayload, `isSubCommodity`, false)) {
            let subcomId = _.get(subPayload, `subCommodity`, '');
            if (commodityMap[subcomId] === undefined) {
                commodityMap[subcomId] = _.get(subPayload, `amount`, 0);
            } else {
                commodityMap[subcomId] = Math.max(_.get(subPayload, `amount`, 0), commodityMap[subcomId]);
            }
        }
    }
    Object.values(commodityMap).forEach(amount => {
        sum += amount;
    });
    return sum;
}
function getProcessedNumOfSubCom(bulk) {
    const commoditiesProcessedBreakdown = _.get(bulk, 'commoditiesProcessedBreakdown', []);
    let sum = 0;
    for (let item of commoditiesProcessedBreakdown) {
        sum += item.amount;
    }
    return sum;
}
